import React, { FC, useState } from "react";
import { Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import { ArrowUpCircle, ArrowDownCircle, PlusCircle, Trash } from "react-bootstrap-icons";
import { Question, RoomTypeQuestions, Choice, ResponseAction, Priority, Difficulty, ExteriorQuestionType } from "../protos/QuestionService_pb";

const QuestionEditorPane: FC<{
    hasEditAccess: Boolean,
    question: Question,
    allQuestions: Question[],
    allQuestionsWithRooms: RoomTypeQuestions[] | undefined,
    allQuestionsWithAreas: ExteriorQuestionType[] | undefined,
    needsSave: Boolean,
    updateQuestion: ((question: Question) => void),
    copyQuestionToRoom: ((question: Question, roomIdx: number) => void) | undefined,
    copyQuestionToArea: ((question: Question, roomIdx: number) => void) | undefined,
    moveQuestionIdxUp: (() => void),
    moveQuestionIdxDown: (() => void),
    saveChanges: (() => void),
    discardChanges: (() => void)
}> = (({ hasEditAccess, question, updateQuestion, allQuestions, allQuestionsWithRooms, allQuestionsWithAreas, needsSave, copyQuestionToRoom, copyQuestionToArea,
    discardChanges, saveChanges, moveQuestionIdxUp, moveQuestionIdxDown }) => {
    const [isConditionalQuestion, setIsConditionalQuestion] = useState(question.hasDependentQuestionId());
    const [choicesList, setChoicesList] = useState(question.getChoicesList());
    const [actionsList, setActionsList] = useState(question.getActionsList());

    React.useEffect(() => {
        setIsConditionalQuestion(question.hasDependentQuestionId());
        setChoicesList(question.getChoicesList())
        setActionsList(question.getActionsList())
        console.log("Setting action list")
        console.log(question.getActionsList());
    }, [question])

    var allDependentQuestionChoices: Choice[] = []
    if (question.hasDependentQuestionId()) {
        let questionWithMatchingId = allQuestions.filter((q) => {
            return q.getId() === question.getDependentQuestionId()
        })
        if (questionWithMatchingId.length === 0) {
            // SHOW ERROR there is no matching question.
        } else if (questionWithMatchingId.length >= 2) {
            // SHOW ERROR there are too many matching questions.
        } else {
            allDependentQuestionChoices = questionWithMatchingId[0].getChoicesList()
        }
    }

    document.addEventListener("keydown", function (e) {
        if (e.keyCode === 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
            e.preventDefault();
            saveChanges();
        }
    }, false);

    let selectededDependentChoicesIdSet: Set<string> = new Set(question.getDependentQuestionChoiceIdsList())

    return <div className="">
        <Row className="align-items-center">
            <Col md="auto">
                <h1>Properties</h1>
            </Col>
            <Col>
            </Col>
            {hasEditAccess && <Col md="auto">
                {needsSave &&
                    <Button variant="outline-success" onClick={saveChanges}>Save question</Button>
                }
            </Col>}
            {hasEditAccess && <Col md="auto">
                {needsSave &&
                    <Button variant="outline-danger" onClick={discardChanges}>Discard changes</Button>
                }
            </Col>}
            {hasEditAccess && <Col md="auto">
                <ArrowUpCircle style={{ cursor: "pointer" }} onClick={() => {
                    moveQuestionIdxUp()
                }} />
            </Col>}
            {hasEditAccess && <Col md="auto">
                <ArrowDownCircle style={{ cursor: "pointer" }} onClick={() => {
                    moveQuestionIdxDown()
                }} />
            </Col>}
            {hasEditAccess && <Col md="auto">
                <Dropdown>
                    <Dropdown.Toggle variant="outline-success">
                        {allQuestionsWithRooms && "Copy to room"}
                        {allQuestionsWithAreas && "Copy to area"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {allQuestionsWithRooms && allQuestionsWithRooms.map((room, idx: number) => {
                            return <Dropdown.Item key={"copy-to-" + idx} onClick={() => {
                                copyQuestionToRoom!(Question.deserializeBinary(question.serializeBinary()), idx)
                            }}>{room.getRoomName()}</Dropdown.Item>
                        })}
                        {allQuestionsWithAreas && allQuestionsWithAreas.map((area, idx: number) => {
                            return <Dropdown.Item key={"copy-to-" + idx} onClick={() => {
                                copyQuestionToArea!(Question.deserializeBinary(question.serializeBinary()), idx)
                            }}>{area.getName()}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>}
        </Row>
        <Form>
            <Form.Group key="id" className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>ID</Form.Label>
                <Form.Control disabled={!hasEditAccess} type="text" value={question.getId()} onChange={(e) => {
                    updateQuestion(Question
                        .deserializeBinary(question.serializeBinary())
                        .setId(e.target.value))
                }} />
            </Form.Group>
            <Form.Group key="prompt" className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Prompt</Form.Label>
                <Form.Control disabled={!hasEditAccess} type="text" value={question.getPrompt()} onChange={(e) => {
                    updateQuestion(Question
                        .deserializeBinary(question.serializeBinary())
                        .setPrompt(e.target.value))
                }} />
            </Form.Group>
            <Form.Group key="hint" className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Hint</Form.Label>
                <Form.Control disabled={!hasEditAccess} as="textarea" rows={3} placeholder="Hint message" value={question.getHint()} onChange={(e) => {
                    updateQuestion(Question
                        .deserializeBinary(question.serializeBinary())
                        .setHint(e.target.value))
                }} />
            </Form.Group>
            <Form.Group key="choices" className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>
                    Choices
                    {hasEditAccess && <PlusCircle className="ms-3" style={{ cursor: "pointer" }} onClick={() => {
                        let edited = [...choicesList]
                        edited.push(new Choice())
                        setChoicesList(edited)
                    }} />}
                </Form.Label>
                {choicesList.map((c, idx) => {
                    return <Row key={"choice-" + idx} className="mt-1 align-items-center">
                        <Col md="auto">
                            <Form.Control disabled={!hasEditAccess} type="text" placeholder="Choice ID" value={c.getId()} onChange={(e) => {
                                let edited = [...choicesList]
                                edited[idx].setId(e.target.value)
                                updateQuestion(Question
                                    .deserializeBinary(question.serializeBinary())
                                    .setChoicesList(edited))
                                setChoicesList(edited)
                            }} />
                        </Col>
                        <Col md="auto">
                            <Form.Control disabled={!hasEditAccess} type="text" placeholder="Choice Message" value={c.getMessage()} onChange={(e) => {
                                let edited = [...choicesList]
                                edited[idx].setMessage(e.target.value)
                                updateQuestion(Question
                                    .deserializeBinary(question.serializeBinary())
                                    .setChoicesList(edited))
                                setChoicesList(edited)
                            }} />
                        </Col>
                        <Col>
                            {hasEditAccess && <Trash style={{ cursor: "pointer" }} onClick={() => {
                                let edited = [...choicesList]
                                edited.splice(idx, 1)
                                updateQuestion(Question
                                    .deserializeBinary(question.serializeBinary())
                                    .setChoicesList(edited))
                                setChoicesList(edited)
                            }} />}
                        </Col>
                    </Row>
                })}
            </Form.Group>
            <Form.Group key="actions" className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>
                    Response Actions
                    {hasEditAccess && <PlusCircle className="ms-3" style={{ cursor: "pointer" }} onClick={() => {
                        let edited = [...actionsList]
                        edited.push(new ResponseAction())
                        setActionsList(edited)
                        console.log(edited);
                        updateQuestion(Question
                            .deserializeBinary(question.serializeBinary())
                            .setActionsList(edited))
                    }} />}
                </Form.Label>
                {actionsList.length === 0 &&
                    <div key="no-actions" className="text-muted ms-3">There are no response actions.</div>
                }
                {actionsList.map((action, idx) => {
                    return <div key={"action-" + action.getId() + "-" + idx} className="ms-3 py-2 border-bottom">
                        <Row className="align-items-center">
                            <Col md="auto">Choice</Col>
                            <Col md="auto">
                                <Form.Select disabled={!hasEditAccess} value={action.getChoiceId()} onChange={(e) => {
                                    action.setChoiceId(e.target.value)
                                    action.setId(e.target.value)
                                    actionsList[idx] = action
                                    updateQuestion(Question
                                        .deserializeBinary(question.serializeBinary())
                                        .setActionsList(actionsList))
                                }}>
                                    <option key="" value="">Unselected</option>
                                    {choicesList.map((c) => {
                                        return <option key={c.getId()} value={c.getId()}>{c.getMessage()}</option>
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-2">
                            <Col md="auto">Priority</Col>
                            <Col md="auto">
                                <Form.Select disabled={!hasEditAccess} value={action.getPriority()} onChange={(e) => {
                                    if (e.target.value === Priority.PRIORITY_COSMETIC.toString()) {
                                        action.setPriority(Priority.PRIORITY_COSMETIC)
                                    } else if (e.target.value === Priority.PRIORITY_FUNCTIONAL.toString()) {
                                        action.setPriority(Priority.PRIORITY_FUNCTIONAL)
                                    } else if (e.target.value === Priority.PRIORITY_SAFETY.toString()) {
                                        action.setPriority(Priority.PRIORITY_SAFETY)
                                    }
                                    actionsList[idx] = action
                                    updateQuestion(Question
                                        .deserializeBinary(question.serializeBinary())
                                        .setActionsList(actionsList))
                                }}>
                                    <option key="" value="">Unselected</option>
                                    <option key={Priority.PRIORITY_COSMETIC.toString()} value={Priority.PRIORITY_COSMETIC.toString()}>Cosmetic</option>
                                    <option key={Priority.PRIORITY_FUNCTIONAL.toString()} value={Priority.PRIORITY_FUNCTIONAL.toString()}>Functional</option>
                                    <option key={Priority.PRIORITY_SAFETY.toString()} value={Priority.PRIORITY_SAFETY.toString()}>Safety</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-2">
                            <Col md="auto">Difficulty</Col>
                            <Col md="auto">
                                <Form.Select disabled={!hasEditAccess} value={action.getDifficulty()} onChange={(e) => {
                                    if (e.target.value === Difficulty.DIFFICULTY_NO_EXPERIENCE.toString()) {
                                        action.setDifficulty(Difficulty.DIFFICULTY_NO_EXPERIENCE)
                                    } else if (e.target.value === Difficulty.DIFFICULTY_HANDYMAN.toString()) {
                                        action.setDifficulty(Difficulty.DIFFICULTY_HANDYMAN)
                                    } else if (e.target.value === Difficulty.DIFFICULTY_CONTRACTOR.toString()) {
                                        action.setDifficulty(Difficulty.DIFFICULTY_CONTRACTOR)
                                    } else if (e.target.value === Difficulty.DIFFICULTY_PRO.toString()) {
                                        action.setDifficulty(Difficulty.DIFFICULTY_PRO)
                                    }
                                    actionsList[idx] = action
                                    updateQuestion(Question
                                        .deserializeBinary(question.serializeBinary())
                                        .setActionsList(actionsList))
                                }}>
                                    <option key="" value="">Unselected</option>
                                    <option key={Difficulty.DIFFICULTY_NO_EXPERIENCE.toString()} value={Difficulty.DIFFICULTY_NO_EXPERIENCE.toString()}>No experience</option>
                                    <option key={Difficulty.DIFFICULTY_HANDYMAN.toString()} value={Difficulty.DIFFICULTY_HANDYMAN.toString()}>Handyman</option>
                                    <option key={Difficulty.DIFFICULTY_PRO.toString()} value={Difficulty.DIFFICULTY_PRO.toString()}>Licensed tradesman</option>
                                    <option key={Difficulty.DIFFICULTY_CONTRACTOR.toString()} value={Difficulty.DIFFICULTY_CONTRACTOR.toString()}>Contractor</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-2">
                            <Col md="auto">Recommendation title</Col>
                            <Col>
                                <Form.Control disabled={!hasEditAccess} type="text" placeholder="Recommendation title" value={action.getTitle()} onChange={(e) => {
                                    action.setTitle(e.target.value)
                                    actionsList[idx] = action
                                    updateQuestion(Question
                                        .deserializeBinary(question.serializeBinary())
                                        .setActionsList(actionsList))
                                }} />
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-2">
                            <Col md="auto">Recommendation</Col>
                            <Col>
                                <Form.Control disabled={!hasEditAccess} as="textarea" rows={4} placeholder="Recommendation" value={action.getMessage()} onChange={(e) => {
                                    action.setMessage(e.target.value)
                                    actionsList[idx] = action
                                    updateQuestion(Question
                                        .deserializeBinary(question.serializeBinary())
                                        .setActionsList(actionsList))
                                }} />
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-2">
                            <Col md="auto">Photo prompt</Col>
                            <Col>
                                <Form.Control disabled={!hasEditAccess} type="text" placeholder="Photo prompt" value={action.getPhotoPrompt()} onChange={(e) => {
                                    action.setPhotoPrompt(e.target.value)
                                    actionsList[idx] = action
                                    updateQuestion(Question
                                        .deserializeBinary(question.serializeBinary())
                                        .setActionsList(actionsList))
                                }} />
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-2">
                            <Col md="auto">Cost (USD)</Col>
                            <Col md="auto">
                                <Form.Control disabled={!hasEditAccess} type="number" placeholder="Min cost" value={action.getEstimatedCostMin()} onChange={(e) => {
                                    action.setEstimatedCostMin(parseFloat(e.target.value))
                                    actionsList[idx] = action
                                    updateQuestion(Question
                                        .deserializeBinary(question.serializeBinary())
                                        .setActionsList(actionsList))
                                }} />
                            </Col>
                            <Col md="auto">–</Col>
                            <Col md="auto">
                                <Form.Control disabled={!hasEditAccess} type="number" placeholder="Max cost" value={action.getEstimatedCostMax()} onChange={(e) => {
                                    action.setEstimatedCostMax(parseFloat(e.target.value))
                                    actionsList[idx] = action
                                    updateQuestion(Question
                                        .deserializeBinary(question.serializeBinary())
                                        .setActionsList(actionsList))
                                }} />
                            </Col>
                        </Row>
                        {hasEditAccess && <Row className="mt-3">
                            <Col><Button variant="outline-danger" onClick={(e) => {
                                let edited = [...actionsList]
                                edited.splice(idx, 1)
                                setActionsList(edited)
                                updateQuestion(Question
                                    .deserializeBinary(question.serializeBinary())
                                    .setActionsList(edited))
                            }}>Delete action</Button></Col>
                        </Row>}
                    </div>
                })}
            </Form.Group>
            <Form.Group key="conditional" className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Check disabled={!hasEditAccess} type="switch" label="Conditional question" checked={isConditionalQuestion} onChange={(e) => {
                    setIsConditionalQuestion(e.target.checked)
                    if (!e.target.checked) {
                        updateQuestion(Question
                            .deserializeBinary(question.serializeBinary())
                            .clearDependentQuestionChoiceIdsList()
                            .clearDependentQuestionId())
                    }
                }} />
            </Form.Group>
            {isConditionalQuestion &&
                <Form.Group key="only_show_if" className="mb-3 ms-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Only show if</Form.Label>
                    <Form.Select disabled={!hasEditAccess} id={question.getId()} placeholder="Choose a question" value={question.getDependentQuestionId()} onChange={(e) => {
                        if (e.target.value !== "-1") {
                            updateQuestion(Question
                                .deserializeBinary(question.serializeBinary())
                                .setDependentQuestionId(e.target.value))
                        }
                    }}>
                        <option value="-1">Choose a question</option>
                        {allQuestions.map((q) => {
                            return <option key={q.getId()} value={q.getId()}>{q.getPrompt()}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            }
            {isConditionalQuestion &&
                <Form.Group key="has_response" className="mb-3 ms-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Has response matching</Form.Label>
                    {allDependentQuestionChoices.map((c) => {
                        return <Form.Check disabled={!hasEditAccess} key={c.getId()} type="checkbox"
                            checked={selectededDependentChoicesIdSet.has(c.getId())}
                            label={c.getMessage()} onChange={(e) => {
                                if (e.target.checked) {
                                    selectededDependentChoicesIdSet.add(c.getId())
                                } else {
                                    selectededDependentChoicesIdSet.delete(c.getId())
                                }
                                updateQuestion(Question.deserializeBinary(question.serializeBinary())
                                    .setDependentQuestionChoiceIdsList(Array.from(selectededDependentChoicesIdSet)))
                            }} />
                    })}
                </Form.Group>
            }
        </Form>
    </div>
});

export default QuestionEditorPane