import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { ReactNode, useEffect, useState } from 'react';
import Header from './Header';
import { Container, Col, Button, Alert, Spinner } from 'react-bootstrap';
import { AdminQuestionServiceClient } from '../protos/AdminQuestionServiceServiceClientPb';
import { CheckLoginRequest } from '../protos/AdminQuestionService_pb';
import AuthInterceptor from '../interceptors/AuthInterceptor';

interface LoginProps {
    children: ((result: CheckLoginResult) => ReactNode)
}

interface CheckLoginResult {
    client: AdminQuestionServiceClient,
    hasEditAccess: Boolean
}

export default function LoginWrapper(props: LoginProps) {
    const [isLoadingLogin, setIsLoadingLogin] = useState<Boolean>(true);
    const [client, setClient] = useState<CheckLoginResult>();
    const [error, setError] = useState<String>();

    const login = useGoogleLogin({
        onSuccess: (resp) => {
            checkLoginWithServer(resp.access_token);
        },
        onError: (error) => {
            setError("There was a problem logging in with Google. Try logging in again.");
            console.log('Login Failed:', error)
        }
    });

    useEffect(() => {
        const token = localStorage.getItem("login-token")
        if (token !== null) {
            checkLoginWithServer(token);
        }
    }, [])

    function checkLoginWithServer(token: string) {
        setIsLoadingLogin(true);
        const authInterceptor = new AuthInterceptor(token, () => {
            // On authorization error
            if (client !== undefined) {
                localStorage.removeItem("login-token");
                setClient(undefined);
                login();
            } else {
                setError("This Google account is not authorized to view this page. Try logging in with another account or request access.");
            }
        });
        let host = window.location.host
        let adminService = new AdminQuestionServiceClient(
            window.location.protocol + '//' + host + '/api', undefined, {
            unaryInterceptors: [authInterceptor],
        });
        let request = new CheckLoginRequest()
        adminService.checkLogin(request, {}).then((response) => {
            setClient({
                client: adminService,
                hasEditAccess: response.getHasEditAccess()
            });
            console.log(response.getIsLoggedIn());
            localStorage.setItem("login-token", token);
        }).catch(err => {
            setError("There was a problem authenticating this user. Try logging in again.");
            console.log("Failed to check login", err);
            localStorage.removeItem("login-token ");
            return;
        }).finally(() => {
            setIsLoadingLogin(false);
        });
    }

    if (client && isLoadingLogin) {
        return <div className="h-100">
            <Header isLoggedIn={false} onLogout={() => { }} />
            <Container className="h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading</span>
                </Spinner>
            </Container>
        </div>
    } else if (client) {
        return <div className="h-100 d-flex flex-grow-1 flex-column overflow-y-hidden">
            <Header isLoggedIn={true} onLogout={() => {
                setClient(undefined)
                setError(undefined)
                googleLogout()
            }} />
            {props.children(client)}
        </div>
    } else {
        return <div>
            <Header isLoggedIn={false} onLogout={() => { }} />
            <Container>
                <Col className='mt-3'>
                    {error && <Alert variant="danger">
                        {error}
                    </Alert>}
                    <h1>Login to Dwellable Pro Page</h1>
                    <p>Click the button below to sign in with Google.</p>
                    <Button onClick={() => {
                        login()
                    }}>Sign in with Google</Button>
                </Col>
            </Container>
        </div>
    }
}