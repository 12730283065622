import { Container } from 'react-bootstrap';
import './App.css';
import InteriorQuestionsPage from './components/InteriorQuestionsRoute';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Outlet, RouterProvider, createBrowserRouter, useOutletContext } from 'react-router-dom';
import ExteriorQuestionsPage from './components/ExteriorQuestionsRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginWrapper from './components/LoginWrapper';
import { AdminQuestionServiceClient } from './protos/AdminQuestionServiceServiceClientPb';
import BasementQuestionsPage from './components/BasementQuestionsRoute';


type ChildPageContext = { client: AdminQuestionServiceClient, hasEditAccess: Boolean };

const router = createBrowserRouter([
  {
    path: "/",
    element: <div className="vh-100">
      <LoginWrapper children={(result) => {
        return <div className="flex-grow d-flex flex-column overflow-y-scroll">
          <Container className="pt-2 overflow-y-scroll flex-grow-1">
            <Outlet context={{ client: result.client, hasEditAccess: result.hasEditAccess }} />
          </Container>
        </div>
      }} />
    </div>,
    children: [
      {
        path: "/",
        element: <InteriorQuestionsPage />,
      },
      {
        path: "/interior",
        element: <InteriorQuestionsPage />,
      },
      {
        path: "/exterior",
        element: <ExteriorQuestionsPage />,
      },
      {
        path: "/basement",
        element: <BasementQuestionsPage />,
      },
    ]
  },
]);

export function GetServiceClient() {
  return useOutletContext<ChildPageContext>().client;
}

export function GetHasEditAccess() {
  return useOutletContext<ChildPageContext>().hasEditAccess;
}

function App() {
  return (
    <div className="overflow-y-hidden vh-100 d-flex flex-column">
      <GoogleOAuthProvider clientId="24344207773-h1hetjgv5nuft2ascsi16fo8mup2v8cp.apps.googleusercontent.com">
        <RouterProvider router={router} />
      </GoogleOAuthProvider>,
    </div>
  );
}

export default App;
