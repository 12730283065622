import { FC, useState, useEffect } from "react";
import { Row, Col, Button, ListGroup, Form } from "react-bootstrap";
import { SaveExteriorQuestionsRequest } from "../protos/AdminQuestionService_pb";
import { GetQuestionsResponse, Question, GetQuestionsRequest, ExteriorQuestionType } from "../protos/QuestionService_pb";
import ConfirmationDialog from "./ConfirmationDialog";
import QuestionCard from "./QuestionCard";
import QuestionEditorPane from "./QuestionEditor";
import { GetHasEditAccess, GetServiceClient } from "../App";

const ExteriorAreaList: FC<{
    hasEditAccess: Boolean,
    lastSavedDate: Date,
    response: GetQuestionsResponse,
    selectedQuestionIdx: number | undefined,
    selectedAreaIdx: number | undefined,
    onEditQuestion: ((
        question: Question,
        areaTypeIdx: number,
        questionIdx: number,
        areaTypeQuestions: ExteriorQuestionType
    ) => () => void),
    onAddQuestion: ((areaTypeIdx: number) => () => void),
    onDeleteQuestion: ((areaTypeIdx: number, questionIdx: number) => () => void),
    onAddArea: (() => void),
    onDeleteArea: ((areaTypeIdx: number) => void),
    onChangeAreaName: (areaIdx: number, newName: string) => void,
    onSaveAllQuestions: (() => void)
}> = (({ hasEditAccess, lastSavedDate, response, selectedQuestionIdx, selectedAreaIdx, onEditQuestion, onAddQuestion, onDeleteQuestion, onAddArea, onDeleteArea, onChangeAreaName, onSaveAllQuestions }) => {
    let questionsPerArea = response.getExteriorQuestionsList();
    const [shownIndices, setShownIndices] = useState(new Set());
    return <div>
        <Row className="align-items-center">
            <Col>
                <h1>Exterior questions</h1>
            </Col>
        </Row>
        {hasEditAccess && <Row className="align-items-center">
            <Col md="auto">
                <Button variant='outline-success' onClick={onSaveAllQuestions}>Save</Button>
            </Col>
            <Col md="auto">
                <Button variant='outline-success' onClick={onAddArea}>Add area</Button>
            </Col>
            <Col md="auto">
                Last saved {lastSavedDate.toLocaleTimeString()}
            </Col>
        </Row> }
        <ListGroup className='mt-3'>
            {questionsPerArea.map((area, areaTypeIdx) => {
                return <ListGroup.Item key={areaTypeIdx}>
                    <Row>
                        <Col>
                            <Form.Control disabled={!hasEditAccess} type="text" value={area.getName() || "Untitled area"} onChange={(e) => {
                                area.setName(e.target.value)
                                onChangeAreaName(areaTypeIdx, e.target.value)
                            }} />
                        </Col>
                        <Col md="auto">
                            {hasEditAccess && <Button variant="outline" onClick={async () => {
                                if (await ConfirmationDialog("Delete area", "Are your sure you want to delete " + area.getName() + "?")) {
                                    onDeleteArea(areaTypeIdx)
                                }
                            }}>Delete</Button> }
                        </Col>
                        <Col md="auto">
                            {shownIndices.has(areaTypeIdx) && <Button variant="outline" onClick={() => {
                                shownIndices.delete(areaTypeIdx)
                                setShownIndices(new Set(shownIndices))
                            }}>Hide</Button>}
                            {!shownIndices.has(areaTypeIdx) && <Button variant="outline" onClick={() => {
                                shownIndices.add(areaTypeIdx)
                                setShownIndices(new Set(shownIndices))
                            }}>Show</Button>}
                        </Col>
                    </Row>

                    {shownIndices.has(areaTypeIdx) && area.getQuestionsList().map((question, questionIdx) => {
                        return <QuestionCard hasEditAccess={hasEditAccess} key={question.getId()} question={question} onEditQuestion={
                            () => {
                                return onEditQuestion(question, areaTypeIdx, questionIdx, area)
                            }} onDeleteQuestion={
                                onDeleteQuestion(areaTypeIdx, questionIdx)
                            } isSelected={selectedAreaIdx === areaTypeIdx && selectedQuestionIdx === questionIdx} />
                    })}
                    {shownIndices.has(areaTypeIdx) && <Button variant="outline-success" className="mt-3" onClick={onAddQuestion(areaTypeIdx)}>Add question</Button>}
                </ListGroup.Item>
            })}
        </ListGroup>
    </div>;
});

export default function ExteriorQuestionsPage() {
    const [questions, setQuestions] = useState<GetQuestionsResponse | null>(null);
    const [selectedQuestion, setSelectedQuestion] = useState<{
        question: Question,
        areaTypeIdx: number,
        questionIdx: number,
        areaTypeQuestions: ExteriorQuestionType
    } | null>(null);
    const [needsSave, setNeedsSave] = useState<Boolean>(false)
    const [lastSavedDate, setLastSavedDate] = useState(new Date())

    const client = GetServiceClient()
    const hasEditAccess = GetHasEditAccess()

    function loadData() {
        let request = new GetQuestionsRequest()
        client.getAllQuestions(request, {}).then((response) => {
            setQuestions(response);
        });
    }

    function saveData() {
        let request = new SaveExteriorQuestionsRequest()
        request.setQuestionsPerAreaList(questions!.getExteriorQuestionsList())
        client.saveExteriorQuestions(request, {}).then((response) => {
            setLastSavedDate(new Date());
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    if (questions != null) {
        return <Row className="h-100">
            <Col md="auto" className="h-100 overflow-y-auto pb-2">
                <ExteriorAreaList hasEditAccess={hasEditAccess} lastSavedDate={lastSavedDate} response={questions} onEditQuestion={(question, areaTypeIdx, questionIdx, areaTypeQuestions) => () => {
                    setSelectedQuestion({ question, areaTypeIdx, questionIdx, areaTypeQuestions })
                }} onAddQuestion={(areaTypeIdx: number) => {
                    return () => {
                        let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                        let questionsList = editedQuestions.getExteriorQuestionsList()[areaTypeIdx].getQuestionsList()
                        if (selectedQuestion == null) {
                            questionsList.push(new Question())
                        } else {
                            questionsList.splice(selectedQuestion.questionIdx, 0, new Question())
                        }
                        setQuestions(editedQuestions)
                    }
                }} onDeleteQuestion={(areaTypeIdx: number, questionIdx: number) => {
                    return () => {
                        if (selectedQuestion?.questionIdx === questionIdx && selectedQuestion.areaTypeIdx === areaTypeIdx) {
                            setSelectedQuestion(null)
                        }
                        let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                        let questionsList = editedQuestions.getExteriorQuestionsList()[areaTypeIdx].getQuestionsList()
                        questionsList.splice(questionIdx, 1)
                        setQuestions(editedQuestions)
                    }
                }} onAddArea={() => {
                    let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                    if (selectedQuestion == null) {
                        editedQuestions.getExteriorQuestionsList().unshift(new ExteriorQuestionType())
                    } else {
                        editedQuestions.getExteriorQuestionsList().splice(selectedQuestion.areaTypeIdx, 0, new ExteriorQuestionType())
                    }
                    setQuestions(editedQuestions)
                }} onDeleteArea={(areaTypeIdx: number) => {
                    let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                    editedQuestions.getExteriorQuestionsList().splice(areaTypeIdx, 1)
                    setQuestions(editedQuestions)
                }} onChangeAreaName={(areaIdx, newName) => {
                    let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                    editedQuestions.getExteriorQuestionsList()[areaIdx].setName(newName)
                    setQuestions(editedQuestions)
                }} onSaveAllQuestions={() => {
                    saveData()
                }} selectedQuestionIdx={selectedQuestion?.questionIdx} selectedAreaIdx={selectedQuestion?.areaTypeIdx} />
            </Col>
            <Col className="h-100 overflow-y-auto pb-2">
                {selectedQuestion &&
                    <QuestionEditorPane hasEditAccess={hasEditAccess} question={selectedQuestion.question} updateQuestion={(question: Question) => {
                        setSelectedQuestion({
                            question: question,
                            areaTypeIdx: selectedQuestion.areaTypeIdx,
                            questionIdx: selectedQuestion.questionIdx,
                            areaTypeQuestions: selectedQuestion.areaTypeQuestions
                        })
                        setNeedsSave(true);
                    }} allQuestionsWithRooms={undefined} allQuestionsWithAreas={questions.getExteriorQuestionsList()} allQuestions={selectedQuestion.areaTypeQuestions.getQuestionsList()} needsSave={needsSave}
                        saveChanges={() => {
                            let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                            let questionsList = editedQuestions.getExteriorQuestionsList()[selectedQuestion.areaTypeIdx].getQuestionsList()
                            let oldId = questionsList[selectedQuestion.questionIdx].getId()
                            let newId = selectedQuestion.question.getId()
                            questionsList[selectedQuestion.questionIdx] = selectedQuestion.question
                            if (oldId !== newId) {
                                questionsList = questionsList.map((q) => {
                                    if (q.hasDependentQuestionId() && q.getDependentQuestionId() === oldId) {
                                        console.log("Updating dependent question ID for question: " + q.getId() + " because ID has changed from " + oldId + " to " + newId)
                                        q.setDependentQuestionId(newId)
                                    }
                                    return q
                                })
                            }
                            setQuestions(editedQuestions)
                            setNeedsSave(false)
                            saveData()
                        }} discardChanges={() => {
                            let questionsList = questions.getExteriorQuestionsList()[selectedQuestion.areaTypeIdx].getQuestionsList()
                            setSelectedQuestion({
                                question: questionsList[selectedQuestion.questionIdx],
                                areaTypeIdx: selectedQuestion.areaTypeIdx,
                                questionIdx: selectedQuestion.questionIdx,
                                areaTypeQuestions: selectedQuestion.areaTypeQuestions
                            })
                        }} copyQuestionToRoom={undefined} copyQuestionToArea={(question, areaIdx) => {
                            console.log(question);
                            let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                            let dependentQuestionExistsInNew = editedQuestions.getExteriorQuestionsList()[areaIdx].getQuestionsList()
                                .find((other) => {
                                    return other.getId() === question.getDependentQuestionId()
                                }) !== undefined
                            if (!dependentQuestionExistsInNew) {
                                question.clearDependentQuestionId()
                                question.clearDependentQuestionChoiceIdsList()
                            }
                            let questionWithSameIdExists = editedQuestions.getExteriorQuestionsList()[areaIdx].getQuestionsList()
                                .find((other) => {
                                    return other.getId() === question.getId()
                                }) !== undefined
                            if (questionWithSameIdExists) {
                                question.setId(question.getId() + "_COPY")
                            }
                            editedQuestions.getExteriorQuestionsList()[areaIdx].getQuestionsList().push(question)
                            setQuestions(editedQuestions)
                            setNeedsSave(false)
                            saveData()
                        }} moveQuestionIdxUp={() => {
                            if (selectedQuestion.questionIdx === 0) {
                                return
                            }
                            let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                            let allAreas = editedQuestions.getExteriorQuestionsList()
                            let area = allAreas[selectedQuestion.areaTypeIdx]
                            let questionsList = area.getQuestionsList()
                            let oldIdx = selectedQuestion.questionIdx
                            let newIdx = selectedQuestion.questionIdx - 1
                            questionsList.splice(newIdx, 0, questionsList.splice(oldIdx, 1)[0]);
                            area.setQuestionsList(questionsList)
                            editedQuestions.setExteriorQuestionsList(allAreas)
                            setQuestions(editedQuestions)
                            setSelectedQuestion({
                                question: questionsList[newIdx],
                                areaTypeIdx: selectedQuestion.areaTypeIdx,
                                questionIdx: newIdx,
                                areaTypeQuestions: area
                            })
                        }} moveQuestionIdxDown={() => {
                            if (selectedQuestion.questionIdx === selectedQuestion.areaTypeQuestions.getQuestionsList().length - 1) {
                                return
                            }
                            let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                            let allAreas = editedQuestions.getExteriorQuestionsList()
                            let area = allAreas[selectedQuestion.areaTypeIdx]
                            let questionsList = area.getQuestionsList()
                            let oldIdx = selectedQuestion.questionIdx
                            let newIdx = selectedQuestion.questionIdx + 1
                            questionsList.splice(newIdx, 0, questionsList.splice(oldIdx, 1)[0]);
                            area.setQuestionsList(questionsList)
                            editedQuestions.setExteriorQuestionsList(allAreas)
                            setQuestions(editedQuestions)
                            setSelectedQuestion({
                                question: questionsList[newIdx],
                                areaTypeIdx: selectedQuestion.areaTypeIdx,
                                questionIdx: newIdx,
                                areaTypeQuestions: area
                            })
                        }} />
                }
            </Col>
        </Row>
    } else {
        return <div></div>
    }
};


