// source: QuestionService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.BasementQuestionType', null, global);
goog.exportSymbol('proto.Choice', null, global);
goog.exportSymbol('proto.Difficulty', null, global);
goog.exportSymbol('proto.ExteriorQuestionType', null, global);
goog.exportSymbol('proto.FloorType', null, global);
goog.exportSymbol('proto.GetQuestionsRequest', null, global);
goog.exportSymbol('proto.GetQuestionsResponse', null, global);
goog.exportSymbol('proto.Priority', null, global);
goog.exportSymbol('proto.Question', null, global);
goog.exportSymbol('proto.ResponseAction', null, global);
goog.exportSymbol('proto.RoomTypeQuestions', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Choice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Choice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Choice.displayName = 'proto.Choice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ResponseAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ResponseAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ResponseAction.displayName = 'proto.ResponseAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Question = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Question.repeatedFields_, null);
};
goog.inherits(proto.Question, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Question.displayName = 'proto.Question';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExteriorQuestionType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ExteriorQuestionType.repeatedFields_, null);
};
goog.inherits(proto.ExteriorQuestionType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExteriorQuestionType.displayName = 'proto.ExteriorQuestionType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BasementQuestionType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BasementQuestionType.repeatedFields_, null);
};
goog.inherits(proto.BasementQuestionType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BasementQuestionType.displayName = 'proto.BasementQuestionType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RoomTypeQuestions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RoomTypeQuestions.repeatedFields_, null);
};
goog.inherits(proto.RoomTypeQuestions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RoomTypeQuestions.displayName = 'proto.RoomTypeQuestions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetQuestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetQuestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetQuestionsRequest.displayName = 'proto.GetQuestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetQuestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetQuestionsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetQuestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetQuestionsResponse.displayName = 'proto.GetQuestionsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Choice.prototype.toObject = function(opt_includeInstance) {
  return proto.Choice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Choice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Choice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Choice}
 */
proto.Choice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Choice;
  return proto.Choice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Choice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Choice}
 */
proto.Choice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Choice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Choice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Choice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Choice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.Choice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Choice} returns this
 */
proto.Choice.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Choice.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Choice} returns this
 */
proto.Choice.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ResponseAction.prototype.toObject = function(opt_includeInstance) {
  return proto.ResponseAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ResponseAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ResponseAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    choiceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priority: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    message: jspb.Message.getFieldWithDefault(msg, 5, ""),
    estimatedCostMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    estimatedCostMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    difficulty: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isAppliance: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    photoPrompt: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ResponseAction}
 */
proto.ResponseAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ResponseAction;
  return proto.ResponseAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ResponseAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ResponseAction}
 */
proto.ResponseAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChoiceId(value);
      break;
    case 3:
      var value = /** @type {!proto.Priority} */ (reader.readEnum());
      msg.setPriority(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEstimatedCostMin(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEstimatedCostMax(value);
      break;
    case 8:
      var value = /** @type {!proto.Difficulty} */ (reader.readEnum());
      msg.setDifficulty(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAppliance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoPrompt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ResponseAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ResponseAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ResponseAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ResponseAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChoiceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEstimatedCostMin();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getEstimatedCostMax();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDifficulty();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getIsAppliance();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ResponseAction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string choice_id = 2;
 * @return {string}
 */
proto.ResponseAction.prototype.getChoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setChoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Priority priority = 3;
 * @return {!proto.Priority}
 */
proto.ResponseAction.prototype.getPriority = function() {
  return /** @type {!proto.Priority} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Priority} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.ResponseAction.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string message = 5;
 * @return {string}
 */
proto.ResponseAction.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float estimated_cost_min = 6;
 * @return {number}
 */
proto.ResponseAction.prototype.getEstimatedCostMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setEstimatedCostMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float estimated_cost_max = 7;
 * @return {number}
 */
proto.ResponseAction.prototype.getEstimatedCostMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setEstimatedCostMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional Difficulty difficulty = 8;
 * @return {!proto.Difficulty}
 */
proto.ResponseAction.prototype.getDifficulty = function() {
  return /** @type {!proto.Difficulty} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.Difficulty} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setDifficulty = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool is_appliance = 9;
 * @return {boolean}
 */
proto.ResponseAction.prototype.getIsAppliance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setIsAppliance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string photo_prompt = 10;
 * @return {string}
 */
proto.ResponseAction.prototype.getPhotoPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.setPhotoPrompt = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ResponseAction} returns this
 */
proto.ResponseAction.prototype.clearPhotoPrompt = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ResponseAction.prototype.hasPhotoPrompt = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Question.repeatedFields_ = [4,5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Question.prototype.toObject = function(opt_includeInstance) {
  return proto.Question.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Question} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Question.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prompt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hint: jspb.Message.getFieldWithDefault(msg, 3, ""),
    choicesList: jspb.Message.toObjectList(msg.getChoicesList(),
    proto.Choice.toObject, includeInstance),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.ResponseAction.toObject, includeInstance),
    dependentQuestionId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dependentQuestionChoiceIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Question}
 */
proto.Question.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Question;
  return proto.Question.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Question}
 */
proto.Question.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHint(value);
      break;
    case 4:
      var value = new proto.Choice;
      reader.readMessage(value,proto.Choice.deserializeBinaryFromReader);
      msg.addChoices(value);
      break;
    case 5:
      var value = new proto.ResponseAction;
      reader.readMessage(value,proto.ResponseAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDependentQuestionId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDependentQuestionChoiceIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Question.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Question.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Choice.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ResponseAction.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDependentQuestionChoiceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.Question.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string prompt = 2;
 * @return {string}
 */
proto.Question.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hint = 3;
 * @return {string}
 */
proto.Question.prototype.getHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.setHint = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.clearHint = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Question.prototype.hasHint = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Choice choices = 4;
 * @return {!Array<!proto.Choice>}
 */
proto.Question.prototype.getChoicesList = function() {
  return /** @type{!Array<!proto.Choice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Choice, 4));
};


/**
 * @param {!Array<!proto.Choice>} value
 * @return {!proto.Question} returns this
*/
proto.Question.prototype.setChoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Choice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Choice}
 */
proto.Question.prototype.addChoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Choice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.clearChoicesList = function() {
  return this.setChoicesList([]);
};


/**
 * repeated ResponseAction actions = 5;
 * @return {!Array<!proto.ResponseAction>}
 */
proto.Question.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.ResponseAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ResponseAction, 5));
};


/**
 * @param {!Array<!proto.ResponseAction>} value
 * @return {!proto.Question} returns this
*/
proto.Question.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ResponseAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ResponseAction}
 */
proto.Question.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ResponseAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional string dependent_question_id = 6;
 * @return {string}
 */
proto.Question.prototype.getDependentQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.setDependentQuestionId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.clearDependentQuestionId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Question.prototype.hasDependentQuestionId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string dependent_question_choice_ids = 7;
 * @return {!Array<string>}
 */
proto.Question.prototype.getDependentQuestionChoiceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.setDependentQuestionChoiceIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.addDependentQuestionChoiceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Question} returns this
 */
proto.Question.prototype.clearDependentQuestionChoiceIdsList = function() {
  return this.setDependentQuestionChoiceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ExteriorQuestionType.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExteriorQuestionType.prototype.toObject = function(opt_includeInstance) {
  return proto.ExteriorQuestionType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExteriorQuestionType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExteriorQuestionType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.Question.toObject, includeInstance),
    icon: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExteriorQuestionType}
 */
proto.ExteriorQuestionType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExteriorQuestionType;
  return proto.ExteriorQuestionType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExteriorQuestionType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExteriorQuestionType}
 */
proto.ExteriorQuestionType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.Question;
      reader.readMessage(value,proto.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExteriorQuestionType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExteriorQuestionType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExteriorQuestionType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExteriorQuestionType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Question.serializeBinaryToWriter
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ExteriorQuestionType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExteriorQuestionType} returns this
 */
proto.ExteriorQuestionType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ExteriorQuestionType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExteriorQuestionType} returns this
 */
proto.ExteriorQuestionType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Question questions = 3;
 * @return {!Array<!proto.Question>}
 */
proto.ExteriorQuestionType.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Question, 3));
};


/**
 * @param {!Array<!proto.Question>} value
 * @return {!proto.ExteriorQuestionType} returns this
*/
proto.ExteriorQuestionType.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Question}
 */
proto.ExteriorQuestionType.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ExteriorQuestionType} returns this
 */
proto.ExteriorQuestionType.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * optional string icon = 4;
 * @return {string}
 */
proto.ExteriorQuestionType.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExteriorQuestionType} returns this
 */
proto.ExteriorQuestionType.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BasementQuestionType.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BasementQuestionType.prototype.toObject = function(opt_includeInstance) {
  return proto.BasementQuestionType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BasementQuestionType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BasementQuestionType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.Question.toObject, includeInstance),
    icon: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BasementQuestionType}
 */
proto.BasementQuestionType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BasementQuestionType;
  return proto.BasementQuestionType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BasementQuestionType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BasementQuestionType}
 */
proto.BasementQuestionType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.Question;
      reader.readMessage(value,proto.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BasementQuestionType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BasementQuestionType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BasementQuestionType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BasementQuestionType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Question.serializeBinaryToWriter
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.BasementQuestionType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BasementQuestionType} returns this
 */
proto.BasementQuestionType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.BasementQuestionType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BasementQuestionType} returns this
 */
proto.BasementQuestionType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Question questions = 3;
 * @return {!Array<!proto.Question>}
 */
proto.BasementQuestionType.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Question, 3));
};


/**
 * @param {!Array<!proto.Question>} value
 * @return {!proto.BasementQuestionType} returns this
*/
proto.BasementQuestionType.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Question}
 */
proto.BasementQuestionType.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BasementQuestionType} returns this
 */
proto.BasementQuestionType.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * optional string icon = 4;
 * @return {string}
 */
proto.BasementQuestionType.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BasementQuestionType} returns this
 */
proto.BasementQuestionType.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RoomTypeQuestions.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RoomTypeQuestions.prototype.toObject = function(opt_includeInstance) {
  return proto.RoomTypeQuestions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RoomTypeQuestions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RoomTypeQuestions.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.Question.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RoomTypeQuestions}
 */
proto.RoomTypeQuestions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RoomTypeQuestions;
  return proto.RoomTypeQuestions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RoomTypeQuestions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RoomTypeQuestions}
 */
proto.RoomTypeQuestions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomName(value);
      break;
    case 3:
      var value = new proto.Question;
      reader.readMessage(value,proto.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RoomTypeQuestions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RoomTypeQuestions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RoomTypeQuestions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RoomTypeQuestions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional string room_name = 2;
 * @return {string}
 */
proto.RoomTypeQuestions.prototype.getRoomName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RoomTypeQuestions} returns this
 */
proto.RoomTypeQuestions.prototype.setRoomName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Question questions = 3;
 * @return {!Array<!proto.Question>}
 */
proto.RoomTypeQuestions.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Question, 3));
};


/**
 * @param {!Array<!proto.Question>} value
 * @return {!proto.RoomTypeQuestions} returns this
*/
proto.RoomTypeQuestions.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Question}
 */
proto.RoomTypeQuestions.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.RoomTypeQuestions} returns this
 */
proto.RoomTypeQuestions.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetQuestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetQuestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetQuestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetQuestionsRequest}
 */
proto.GetQuestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetQuestionsRequest;
  return proto.GetQuestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetQuestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetQuestionsRequest}
 */
proto.GetQuestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetQuestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetQuestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetQuestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.GetQuestionsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetQuestionsRequest} returns this
 */
proto.GetQuestionsRequest.prototype.setToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.GetQuestionsRequest} returns this
 */
proto.GetQuestionsRequest.prototype.clearToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetQuestionsRequest.prototype.hasToken = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetQuestionsResponse.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetQuestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetQuestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetQuestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionsPerRoomList: jspb.Message.toObjectList(msg.getQuestionsPerRoomList(),
    proto.RoomTypeQuestions.toObject, includeInstance),
    exteriorQuestionsList: jspb.Message.toObjectList(msg.getExteriorQuestionsList(),
    proto.ExteriorQuestionType.toObject, includeInstance),
    basementQuestionsList: jspb.Message.toObjectList(msg.getBasementQuestionsList(),
    proto.BasementQuestionType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetQuestionsResponse}
 */
proto.GetQuestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetQuestionsResponse;
  return proto.GetQuestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetQuestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetQuestionsResponse}
 */
proto.GetQuestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.RoomTypeQuestions;
      reader.readMessage(value,proto.RoomTypeQuestions.deserializeBinaryFromReader);
      msg.addQuestionsPerRoom(value);
      break;
    case 3:
      var value = new proto.ExteriorQuestionType;
      reader.readMessage(value,proto.ExteriorQuestionType.deserializeBinaryFromReader);
      msg.addExteriorQuestions(value);
      break;
    case 4:
      var value = new proto.BasementQuestionType;
      reader.readMessage(value,proto.BasementQuestionType.deserializeBinaryFromReader);
      msg.addBasementQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetQuestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetQuestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetQuestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetQuestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionsPerRoomList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.RoomTypeQuestions.serializeBinaryToWriter
    );
  }
  f = message.getExteriorQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ExteriorQuestionType.serializeBinaryToWriter
    );
  }
  f = message.getBasementQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.BasementQuestionType.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.GetQuestionsResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetQuestionsResponse} returns this
 */
proto.GetQuestionsResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RoomTypeQuestions questions_per_room = 2;
 * @return {!Array<!proto.RoomTypeQuestions>}
 */
proto.GetQuestionsResponse.prototype.getQuestionsPerRoomList = function() {
  return /** @type{!Array<!proto.RoomTypeQuestions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RoomTypeQuestions, 2));
};


/**
 * @param {!Array<!proto.RoomTypeQuestions>} value
 * @return {!proto.GetQuestionsResponse} returns this
*/
proto.GetQuestionsResponse.prototype.setQuestionsPerRoomList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.RoomTypeQuestions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RoomTypeQuestions}
 */
proto.GetQuestionsResponse.prototype.addQuestionsPerRoom = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.RoomTypeQuestions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetQuestionsResponse} returns this
 */
proto.GetQuestionsResponse.prototype.clearQuestionsPerRoomList = function() {
  return this.setQuestionsPerRoomList([]);
};


/**
 * repeated ExteriorQuestionType exterior_questions = 3;
 * @return {!Array<!proto.ExteriorQuestionType>}
 */
proto.GetQuestionsResponse.prototype.getExteriorQuestionsList = function() {
  return /** @type{!Array<!proto.ExteriorQuestionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExteriorQuestionType, 3));
};


/**
 * @param {!Array<!proto.ExteriorQuestionType>} value
 * @return {!proto.GetQuestionsResponse} returns this
*/
proto.GetQuestionsResponse.prototype.setExteriorQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ExteriorQuestionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExteriorQuestionType}
 */
proto.GetQuestionsResponse.prototype.addExteriorQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ExteriorQuestionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetQuestionsResponse} returns this
 */
proto.GetQuestionsResponse.prototype.clearExteriorQuestionsList = function() {
  return this.setExteriorQuestionsList([]);
};


/**
 * repeated BasementQuestionType basement_questions = 4;
 * @return {!Array<!proto.BasementQuestionType>}
 */
proto.GetQuestionsResponse.prototype.getBasementQuestionsList = function() {
  return /** @type{!Array<!proto.BasementQuestionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BasementQuestionType, 4));
};


/**
 * @param {!Array<!proto.BasementQuestionType>} value
 * @return {!proto.GetQuestionsResponse} returns this
*/
proto.GetQuestionsResponse.prototype.setBasementQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.BasementQuestionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BasementQuestionType}
 */
proto.GetQuestionsResponse.prototype.addBasementQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.BasementQuestionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetQuestionsResponse} returns this
 */
proto.GetQuestionsResponse.prototype.clearBasementQuestionsList = function() {
  return this.setBasementQuestionsList([]);
};


/**
 * @enum {number}
 */
proto.FloorType = {
  FLOOR_BASEMENT: 0,
  FLOOR_GROUND: 1,
  FLOOR_SECOND: 2,
  FLOOR_THIRD: 3
};

/**
 * @enum {number}
 */
proto.Priority = {
  PRIORITY_COSMETIC: 0,
  PRIORITY_FUNCTIONAL: 1,
  PRIORITY_SAFETY: 2
};

/**
 * @enum {number}
 */
proto.Difficulty = {
  DIFFICULTY_NO_EXPERIENCE: 0,
  DIFFICULTY_HANDYMAN: 1,
  DIFFICULTY_PRO: 2,
  DIFFICULTY_CONTRACTOR: 3
};

goog.object.extend(exports, proto);
