import { FC } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

interface HeaderProps {
    isLoggedIn: Boolean,
    onLogout: () => void
}

const Header: FC<HeaderProps> = (props) => {
    let isLoggedIn = props.isLoggedIn
    return (
        <div>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="/">Dwellable</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    {isLoggedIn && <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link as={NavLink} to="/interior" >Interior</Nav.Link>
                            <Nav.Link as={NavLink} to="/exterior" >Exterior</Nav.Link>
                            <Nav.Link as={NavLink} to="/basement" >Basement</Nav.Link>
                            <Nav.Link onClick={() => {props.onLogout()}}>Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>}
                </Container>
            </Navbar>
        </div>
    )
}

export default Header;