import { FC, useEffect, useState } from 'react';


import { GetQuestionsRequest, GetQuestionsResponse, Question, RoomTypeQuestions } from '../protos/QuestionService_pb';
import { Button, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { SaveInteriorQuestionsRequest } from '../protos/AdminQuestionService_pb';
import ConfirmationDialog from './ConfirmationDialog';
import QuestionCard from './QuestionCard';
import QuestionEditorPane from './QuestionEditor';
import { GetHasEditAccess, GetServiceClient } from '../App';


const InteriorQuestionsList: FC<{
    hasEditAccess: Boolean,
    lastSavedDate: Date,
    response: GetQuestionsResponse,
    selectedQuestionIdx: number | undefined,
    selectedRoomIdx: number | undefined,
    onEditQuestion: ((
        question: Question,
        roomTypeIdx: number,
        questionIdx: number,
        roomTypeQuestions: RoomTypeQuestions
    ) => () => void),
    onAddQuestion: ((roomTypeIdx: number) => () => void),
    onDeleteQuestion: ((roomTypeIdx: number, questionIdx: number) => () => void),
    onAddRoom: (() => void),
    onDeleteRoom: ((roomTypeIdx: number) => void),
    onChangeRoomName: (roomIdx: number, newName: string) => void,
    onSaveAllQuestions: (() => void)
}> = (({ hasEditAccess, lastSavedDate, response, selectedQuestionIdx, selectedRoomIdx, onEditQuestion, onAddQuestion, onDeleteQuestion, onAddRoom, onDeleteRoom, onChangeRoomName, onSaveAllQuestions }) => {
    let questionsPerRoom = response.getQuestionsPerRoomList();
    const [shownIndices, setShownIndices] = useState(new Set());
    return <div>
        <Row className="align-items-center">
            <Col>
                <h1>Interior questions</h1>
            </Col>
        </Row>
        { hasEditAccess && <Row className="align-items-center">
            <Col md="auto">
                <Button variant='outline-success' onClick={onSaveAllQuestions}>Save</Button>
            </Col>
            <Col md="auto">
                <Button variant='outline-success' onClick={onAddRoom}>Add room</Button>
            </Col>
            <Col md="auto">
                Last saved { lastSavedDate.toLocaleTimeString() }
            </Col>
        </Row> }
        <ListGroup className='mt-3'>
            {questionsPerRoom.map((room, roomTypeIdx) => {
                return <ListGroup.Item key={roomTypeIdx}>
                    <Row>
                        <Col>
                            <Form.Control disabled={!hasEditAccess} type="text" value={room.getRoomName() || "Untitled room"} onChange={(e) => {
                                room.setRoomName(e.target.value)
                                onChangeRoomName(roomTypeIdx, e.target.value)
                            }} />
                        </Col>
                        <Col md="auto">
                            { hasEditAccess && <Button variant="outline" onClick={async () => {
                                if (await ConfirmationDialog("Delete room", "Are your sure you want to delete " + room.getRoomName() + "?")) {
                                    onDeleteRoom(roomTypeIdx)
                                }
                            }}>Delete</Button> }
                        </Col>
                        <Col md="auto">
                            {shownIndices.has(roomTypeIdx) && <Button variant="outline" onClick={() => {
                                shownIndices.delete(roomTypeIdx)
                                setShownIndices(new Set(shownIndices))
                            }}>Hide</Button>}
                            {!shownIndices.has(roomTypeIdx) && <Button variant="outline" onClick={() => {
                                shownIndices.add(roomTypeIdx)
                                setShownIndices(new Set(shownIndices))
                            }}>Show</Button>}
                        </Col>
                    </Row>

                    {shownIndices.has(roomTypeIdx) && room.getQuestionsList().map((question, questionIdx) => {
                        return <QuestionCard hasEditAccess={hasEditAccess} key={question.getId()} question={question} onEditQuestion={
                            () => {
                                return onEditQuestion(question, roomTypeIdx, questionIdx, room)
                            }} onDeleteQuestion={
                                onDeleteQuestion(roomTypeIdx, questionIdx)
                            } isSelected={selectedRoomIdx === roomTypeIdx && selectedQuestionIdx === questionIdx} />
                    })}
                    {shownIndices.has(roomTypeIdx) && <Button variant="outline-success" className="mt-3" onClick={onAddQuestion(roomTypeIdx)}>Add question</Button>}
                </ListGroup.Item>
            })}
        </ListGroup>
    </div>;
});

export default function InteriorQuestionsPage() {
    const [questions, setQuestions] = useState<GetQuestionsResponse | null>(null);
    const [selectedQuestion, setSelectedQuestion] = useState<{
        question: Question,
        roomTypeIdx: number,
        questionIdx: number,
        roomTypeQuestions: RoomTypeQuestions
    } | null>(null);
    const [needsSave, setNeedsSave] = useState<Boolean>(false)
    const [lastSavedDate, setLastSavedDate] = useState(new Date())

    const client = GetServiceClient()
    const hasEditAccess = GetHasEditAccess()

    function loadData() {
        let request = new GetQuestionsRequest()
        client.getAllQuestions(request, {}).then((response) => {
            setQuestions(response);
        });
    }

    function saveData() {
        let request = new SaveInteriorQuestionsRequest()
        request.setQuestionsPerRoomList(questions!.getQuestionsPerRoomList())
        client.saveInteriorQuestions(request, {}).then((response) => {
            setLastSavedDate(new Date());
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    if (questions != null) {
        return <Row className="h-100">
            <Col md="auto" className="h-100 overflow-y-auto pb-2">
                <InteriorQuestionsList hasEditAccess={hasEditAccess} lastSavedDate={lastSavedDate} response={questions} onEditQuestion={(question, roomTypeIdx, questionIdx, roomTypeQuestions) => () => {
                    setSelectedQuestion({ question, roomTypeIdx, questionIdx, roomTypeQuestions })
                }} onAddQuestion={(roomTypeIdx: number) => {
                    return () => {
                        let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                        let questionsList = editedQuestions.getQuestionsPerRoomList()[roomTypeIdx].getQuestionsList()
                        if (selectedQuestion == null) {
                            questionsList.push(new Question())
                        } else {
                            questionsList.splice(selectedQuestion.questionIdx, 0, new Question())
                        }
                        setQuestions(editedQuestions)
                    }
                }} onDeleteQuestion={(roomTypeIdx: number, questionIdx: number) => {
                    return () => {
                        if (selectedQuestion?.questionIdx === questionIdx && selectedQuestion.roomTypeIdx === roomTypeIdx) {
                            setSelectedQuestion(null)
                        }
                        let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                        let questionsList = editedQuestions.getQuestionsPerRoomList()[roomTypeIdx].getQuestionsList()
                        questionsList.splice(questionIdx, 1)
                        setQuestions(editedQuestions)
                    }
                }} onAddRoom={() => {
                    let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                    if (selectedQuestion == null) {
                        editedQuestions.getQuestionsPerRoomList().unshift(new RoomTypeQuestions())
                    } else {
                        editedQuestions.getQuestionsPerRoomList().splice(selectedQuestion.roomTypeIdx, 0, new RoomTypeQuestions())
                    }
                    setQuestions(editedQuestions)
                }} onDeleteRoom={(roomTypeIdx: number) => {
                    let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                    editedQuestions.getQuestionsPerRoomList().splice(roomTypeIdx, 1)
                    setQuestions(editedQuestions)
                }} onChangeRoomName={(roomIdx, newName) => {
                    let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                    editedQuestions.getQuestionsPerRoomList()[roomIdx].setRoomName(newName)
                    setQuestions(editedQuestions)
                }} onSaveAllQuestions={() => {
                    saveData()
                }} selectedQuestionIdx={selectedQuestion?.questionIdx} selectedRoomIdx={selectedQuestion?.roomTypeIdx} />
            </Col>
            <Col className="h-100 overflow-y-auto pb-2">
                {selectedQuestion &&
                    <QuestionEditorPane hasEditAccess={hasEditAccess} question={selectedQuestion.question} updateQuestion={(question: Question) => {
                        setSelectedQuestion({
                            question: question,
                            roomTypeIdx: selectedQuestion.roomTypeIdx,
                            questionIdx: selectedQuestion.questionIdx,
                            roomTypeQuestions: selectedQuestion.roomTypeQuestions
                        })
                        setNeedsSave(true);
                    }} allQuestionsWithAreas={undefined} allQuestionsWithRooms={questions.getQuestionsPerRoomList()} allQuestions={selectedQuestion.roomTypeQuestions.getQuestionsList()} needsSave={needsSave}
                        saveChanges={() => {
                            let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                            let questionsList = editedQuestions.getQuestionsPerRoomList()[selectedQuestion.roomTypeIdx].getQuestionsList()
                            let oldId = questionsList[selectedQuestion.questionIdx].getId()
                            let newId = selectedQuestion.question.getId()
                            questionsList[selectedQuestion.questionIdx] = selectedQuestion.question
                            if (oldId !== newId) {
                                questionsList = questionsList.map((q) => {
                                    if (q.hasDependentQuestionId() && q.getDependentQuestionId() === oldId) {
                                        console.log("Updating dependent question ID for question: " + q.getId() + " because ID has changed from " + oldId + " to " + newId)
                                        q.setDependentQuestionId(newId)
                                    }
                                    return q
                                })
                            }
                            setQuestions(editedQuestions)
                            setNeedsSave(false)
                            saveData()
                        }} discardChanges={() => {
                            let questionsList = questions.getQuestionsPerRoomList()[selectedQuestion.roomTypeIdx].getQuestionsList()
                            setSelectedQuestion({
                                question: questionsList[selectedQuestion.questionIdx],
                                roomTypeIdx: selectedQuestion.roomTypeIdx,
                                questionIdx: selectedQuestion.questionIdx,
                                roomTypeQuestions: selectedQuestion.roomTypeQuestions
                            })
                        }} copyQuestionToArea={undefined} copyQuestionToRoom={(question, roomIdx) => {
                            console.log(question);
                            let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                            let dependentQuestionExistsInNew = editedQuestions.getQuestionsPerRoomList()[roomIdx].getQuestionsList()
                                .find((other) => {
                                    return other.getId() === question.getDependentQuestionId()
                                }) !== undefined
                            if (!dependentQuestionExistsInNew) {
                                question.clearDependentQuestionId()
                                question.clearDependentQuestionChoiceIdsList()
                            }
                            let questionWithSameIdExists = editedQuestions.getQuestionsPerRoomList()[roomIdx].getQuestionsList()
                                .find((other) => {
                                    return other.getId() === question.getId()
                                }) !== undefined
                            if (questionWithSameIdExists) {
                                question.setId(question.getId() + "_COPY")
                            }
                            editedQuestions.getQuestionsPerRoomList()[roomIdx].getQuestionsList().push(question)
                            setQuestions(editedQuestions)
                            setNeedsSave(false)
                            saveData()
                        }} moveQuestionIdxUp={() => {
                            if (selectedQuestion.questionIdx === 0) {
                                return
                            }
                            let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                            let allRooms = editedQuestions.getQuestionsPerRoomList()
                            let room = allRooms[selectedQuestion.roomTypeIdx]
                            let questionsList = room.getQuestionsList()
                            let oldIdx = selectedQuestion.questionIdx
                            let newIdx = selectedQuestion.questionIdx - 1
                            questionsList.splice(newIdx, 0, questionsList.splice(oldIdx, 1)[0]);
                            room.setQuestionsList(questionsList)
                            editedQuestions.setQuestionsPerRoomList(allRooms)
                            setQuestions(editedQuestions)
                            setSelectedQuestion({
                                question: questionsList[newIdx],
                                roomTypeIdx: selectedQuestion.roomTypeIdx,
                                questionIdx: newIdx,
                                roomTypeQuestions: room
                            })
                        }} moveQuestionIdxDown={() => {
                            if (selectedQuestion.questionIdx === selectedQuestion.roomTypeQuestions.getQuestionsList().length - 1) {
                                return
                            }
                            let editedQuestions = GetQuestionsResponse.deserializeBinary(questions.serializeBinary())
                            let allRooms = editedQuestions.getQuestionsPerRoomList()
                            let room = allRooms[selectedQuestion.roomTypeIdx]
                            let questionsList = room.getQuestionsList()
                            let oldIdx = selectedQuestion.questionIdx
                            let newIdx = selectedQuestion.questionIdx + 1
                            questionsList.splice(newIdx, 0, questionsList.splice(oldIdx, 1)[0]);
                            room.setQuestionsList(questionsList)
                            editedQuestions.setQuestionsPerRoomList(allRooms)
                            setQuestions(editedQuestions)
                            setSelectedQuestion({
                                question: questionsList[newIdx],
                                roomTypeIdx: selectedQuestion.roomTypeIdx,
                                questionIdx: newIdx,
                                roomTypeQuestions: room
                            })
                        }} />
                }
            </Col>
        </Row>
    } else {
        return <div></div>
    }
};


