import { FC } from "react";
import { Card, Button } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { Question } from "../protos/QuestionService_pb";

const QuestionCard: FC<{
    hasEditAccess: Boolean,
    question: Question,
    isSelected: Boolean,
    onEditQuestion: ((question: Question) => () => void),
    onDeleteQuestion: () => void
}> = (({ hasEditAccess, question, isSelected, onEditQuestion, onDeleteQuestion }) => {
    return <Card className={isSelected ? "mt-2 border-left border-4 border-success" : "mt-2"} style={{ cursor: "pointer" }} onClick={
        onEditQuestion(question)
    }>
        <Card.Header className="d-flex justify-content-between align-items-center">{question.getPrompt()} {hasEditAccess && <Trash style={{ cursor: "pointer" }} onClick={
            (e) => {
                e.stopPropagation()
                onDeleteQuestion()
            }
        } />}</Card.Header>
        <Card.Body>
            {question.getChoicesList().map((choice) => {
                return <Button key={choice.getId()} variant="outline-dark mx-2">{choice.getMessage()}</Button>
            })}
        </Card.Body>
    </Card>
});

export default QuestionCard 