import { Request, StatusCode, UnaryInterceptor, UnaryResponse } from "grpc-web";

export default class AuthInterceptor implements UnaryInterceptor<any, any> {
    token: string
    onPermissionDenied: (() => void)

    constructor(token: string, onPermissionDenied: (() => void)) {
        this.token = token
        this.onPermissionDenied = onPermissionDenied
    }

    intercept(request: Request<any, any>, invoker: (request: Request<any, any>) => Promise<UnaryResponse<any, any>>) {
        const metadata = request.getMetadata()
        metadata.Authorization = 'Bearer ' + this.token
        return new Promise<UnaryResponse<any, any>>((resolve, reject) => {
            invoker(request).then((response) => {
                resolve(response);
            }).catch((err) => {
                if (err.code === StatusCode.PERMISSION_DENIED) {
                    this.onPermissionDenied()
                } else {
                    reject(err);
                }
            });
        })
    }
}