/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: AdminQuestionService.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as AdminQuestionService_pb from './AdminQuestionService_pb'; // proto import: "AdminQuestionService.proto"
import * as QuestionService_pb from './QuestionService_pb'; // proto import: "QuestionService.proto"


export class AdminQuestionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCheckLogin = new grpcWeb.MethodDescriptor(
    '/AdminQuestionService/CheckLogin',
    grpcWeb.MethodType.UNARY,
    AdminQuestionService_pb.CheckLoginRequest,
    AdminQuestionService_pb.CheckLoginResponse,
    (request: AdminQuestionService_pb.CheckLoginRequest) => {
      return request.serializeBinary();
    },
    AdminQuestionService_pb.CheckLoginResponse.deserializeBinary
  );

  checkLogin(
    request: AdminQuestionService_pb.CheckLoginRequest,
    metadata?: grpcWeb.Metadata | null): Promise<AdminQuestionService_pb.CheckLoginResponse>;

  checkLogin(
    request: AdminQuestionService_pb.CheckLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: AdminQuestionService_pb.CheckLoginResponse) => void): grpcWeb.ClientReadableStream<AdminQuestionService_pb.CheckLoginResponse>;

  checkLogin(
    request: AdminQuestionService_pb.CheckLoginRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: AdminQuestionService_pb.CheckLoginResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AdminQuestionService/CheckLogin',
        request,
        metadata || {},
        this.methodDescriptorCheckLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AdminQuestionService/CheckLogin',
    request,
    metadata || {},
    this.methodDescriptorCheckLogin);
  }

  methodDescriptorGetAllQuestions = new grpcWeb.MethodDescriptor(
    '/AdminQuestionService/GetAllQuestions',
    grpcWeb.MethodType.UNARY,
    QuestionService_pb.GetQuestionsRequest,
    QuestionService_pb.GetQuestionsResponse,
    (request: QuestionService_pb.GetQuestionsRequest) => {
      return request.serializeBinary();
    },
    QuestionService_pb.GetQuestionsResponse.deserializeBinary
  );

  getAllQuestions(
    request: QuestionService_pb.GetQuestionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<QuestionService_pb.GetQuestionsResponse>;

  getAllQuestions(
    request: QuestionService_pb.GetQuestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: QuestionService_pb.GetQuestionsResponse) => void): grpcWeb.ClientReadableStream<QuestionService_pb.GetQuestionsResponse>;

  getAllQuestions(
    request: QuestionService_pb.GetQuestionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: QuestionService_pb.GetQuestionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AdminQuestionService/GetAllQuestions',
        request,
        metadata || {},
        this.methodDescriptorGetAllQuestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AdminQuestionService/GetAllQuestions',
    request,
    metadata || {},
    this.methodDescriptorGetAllQuestions);
  }

  methodDescriptorSaveInteriorQuestions = new grpcWeb.MethodDescriptor(
    '/AdminQuestionService/SaveInteriorQuestions',
    grpcWeb.MethodType.UNARY,
    AdminQuestionService_pb.SaveInteriorQuestionsRequest,
    AdminQuestionService_pb.SaveInteriorQuestionsResponse,
    (request: AdminQuestionService_pb.SaveInteriorQuestionsRequest) => {
      return request.serializeBinary();
    },
    AdminQuestionService_pb.SaveInteriorQuestionsResponse.deserializeBinary
  );

  saveInteriorQuestions(
    request: AdminQuestionService_pb.SaveInteriorQuestionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<AdminQuestionService_pb.SaveInteriorQuestionsResponse>;

  saveInteriorQuestions(
    request: AdminQuestionService_pb.SaveInteriorQuestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: AdminQuestionService_pb.SaveInteriorQuestionsResponse) => void): grpcWeb.ClientReadableStream<AdminQuestionService_pb.SaveInteriorQuestionsResponse>;

  saveInteriorQuestions(
    request: AdminQuestionService_pb.SaveInteriorQuestionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: AdminQuestionService_pb.SaveInteriorQuestionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AdminQuestionService/SaveInteriorQuestions',
        request,
        metadata || {},
        this.methodDescriptorSaveInteriorQuestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AdminQuestionService/SaveInteriorQuestions',
    request,
    metadata || {},
    this.methodDescriptorSaveInteriorQuestions);
  }

  methodDescriptorSaveExteriorQuestions = new grpcWeb.MethodDescriptor(
    '/AdminQuestionService/SaveExteriorQuestions',
    grpcWeb.MethodType.UNARY,
    AdminQuestionService_pb.SaveExteriorQuestionsRequest,
    AdminQuestionService_pb.SaveExteriorQuestionsResponse,
    (request: AdminQuestionService_pb.SaveExteriorQuestionsRequest) => {
      return request.serializeBinary();
    },
    AdminQuestionService_pb.SaveExteriorQuestionsResponse.deserializeBinary
  );

  saveExteriorQuestions(
    request: AdminQuestionService_pb.SaveExteriorQuestionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<AdminQuestionService_pb.SaveExteriorQuestionsResponse>;

  saveExteriorQuestions(
    request: AdminQuestionService_pb.SaveExteriorQuestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: AdminQuestionService_pb.SaveExteriorQuestionsResponse) => void): grpcWeb.ClientReadableStream<AdminQuestionService_pb.SaveExteriorQuestionsResponse>;

  saveExteriorQuestions(
    request: AdminQuestionService_pb.SaveExteriorQuestionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: AdminQuestionService_pb.SaveExteriorQuestionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AdminQuestionService/SaveExteriorQuestions',
        request,
        metadata || {},
        this.methodDescriptorSaveExteriorQuestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AdminQuestionService/SaveExteriorQuestions',
    request,
    metadata || {},
    this.methodDescriptorSaveExteriorQuestions);
  }

  methodDescriptorSaveBasementQuestions = new grpcWeb.MethodDescriptor(
    '/AdminQuestionService/SaveBasementQuestions',
    grpcWeb.MethodType.UNARY,
    AdminQuestionService_pb.SaveBasementQuestionsRequest,
    AdminQuestionService_pb.SaveBasementQuestionsResponse,
    (request: AdminQuestionService_pb.SaveBasementQuestionsRequest) => {
      return request.serializeBinary();
    },
    AdminQuestionService_pb.SaveBasementQuestionsResponse.deserializeBinary
  );

  saveBasementQuestions(
    request: AdminQuestionService_pb.SaveBasementQuestionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<AdminQuestionService_pb.SaveBasementQuestionsResponse>;

  saveBasementQuestions(
    request: AdminQuestionService_pb.SaveBasementQuestionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: AdminQuestionService_pb.SaveBasementQuestionsResponse) => void): grpcWeb.ClientReadableStream<AdminQuestionService_pb.SaveBasementQuestionsResponse>;

  saveBasementQuestions(
    request: AdminQuestionService_pb.SaveBasementQuestionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: AdminQuestionService_pb.SaveBasementQuestionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/AdminQuestionService/SaveBasementQuestions',
        request,
        metadata || {},
        this.methodDescriptorSaveBasementQuestions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/AdminQuestionService/SaveBasementQuestions',
    request,
    metadata || {},
    this.methodDescriptorSaveBasementQuestions);
  }

}

